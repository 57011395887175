import React, {useRef} from "react"
import {Form} from "sections/Contact/Form"
import SEO from "components/seo"
import {ContactHeader} from "sections/Contact/ContactHeader"
import {MainSection} from "sections/MainSection"
import {Waves} from "components/Waves"
import {CONTACT_IFRAME_NAME} from "sections/Contact/Form/form";
import {FormWrapper} from "sections/Contact/styles";

const Contact = () => {
  const formRef = useRef(null);
  const handleScroll = (event) => {
    window.scroll({
      top: formRef.current.offsetTop + event.data.offset,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <SEO title="kontakt - doradztwo, wycena i montaż klimatyzacji"/>
      <MainSection>
        <ContactHeader/>
      </MainSection>
      <div style={{position: "relative"}} ref={formRef}>
        <Waves top={0} left={0}/>
        <FormWrapper>
          <Form name={CONTACT_IFRAME_NAME} handleScroll={handleScroll} src={process.env.GATSBY_CONTACT_FORM_URL}/>
        </FormWrapper>
      </div>
    </>
  )
}

export default Contact
