import styled from "styled-components"
import { PageWrapper } from "../../../shared/Styles/Wrappers/"

import { BigSubpageTitle } from "../../../components/Typography"

import ContactBg from "assets/icons/contact_bg.svg"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 65px;
  position: relative;
  &::after {
    content: "";
    right: 0;
    bottom: 0;
    position: absolute;
    width: 50px;
    height: 160px;
    background: ${props => props.theme.color.secondary};
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 100vh;
    width: 35vw;
    background-color: #fff;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-top: 40px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    padding-top: 0px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    display: flex;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    min-height: 100vh;
    &:before {
      display: none;
    }
  }
  @media screen and (max-width: 378px) {
    min-height: 660px;
  }
`

export const ImageBox = styled.div`
  height: 100%;
  width: 54.5vw;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    height: 20px;
    background-color: ${props => props.theme.color.secondary};
    z-index: 3;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.color.secondary};
    width: 70px;
    height: 70px;
    z-index: 3;
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.tabletBig}) {
      width: 40px;
      height: 40px;
    }
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.tabletSmall}) {
      display: none;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 50%;
  }
`

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    position: relative;
    left: 0;
    transform: translateX(0);
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    justify-content: flex-start;
    background-color: ${props => props.theme.color.primary};
    background-image: url(${ContactBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const TextBox = styled.div`
  max-width: 325px;
  width: 100%;
  padding-top: 65px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-top: 40px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    max-width: 250px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    max-width: 100%;
    justify-content: flex-start;
  }
`

export const Title = styled.div`
  color: ${props => (props.orange ? props.theme.color.secondary : "#fff")};
`

export const PageTitle = styled(BigSubpageTitle)`
  margin-bottom: 40px;
  color: #fff;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    margin-bottom: 20px;
  }
`

export const TitlesWrap = styled.div`
  ${PageTitle},
  ${Title} {
    font-size: 36px;
    line-height: 1.16;
    font-weight: 700;
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.laptopStandard}) {
      font-size: 28px;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    ${PageTitle} {
      font-size: 32px;
    }
    ${Title} {
      font-size: 20px;
    }
  }
`

export const ContactDetail = styled.div`
  display: flex;
  &:not(:last-child) {
    align-items: center;
    margin-bottom: 30px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    &:not(:last-child) {
      margin-bottom: 15px;
      align-items: center;
    }
  }
`

export const ContactLink = styled.a`
  display: inline-block;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 14px;
  }
`

export const ContactSpan = styled.span`
  display: inline-block;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 14px;
  }
`

export const ContactDetails = styled.div`
  padding-bottom: 60px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    margin: 30px 0;
  }
`

export const IconWrapper = styled.div`
  margin-right: 45px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin-right: 30px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    max-width: 26px;
    margin-right: 20px;
    svg {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const TextDetail = styled.div``
