import styled from "styled-components"

export const IframeWrapper = styled.div`
`

export const Iframe = styled.iframe`
    width: 100%;
    display: block;
    border: none
`

export const TitleContainer = styled.div`
    padding-top: 80px;
    padding-bottom: 50px;
`
