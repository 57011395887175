import React from "react"
import {
  ContactLink,
  ContactSpan,
  Container,
  ImageBox,
  Wrapper,
  TextBox,
  PageTitle,
  TitlesWrap,
  Title,
  ContactDetails,
  ContactDetail,
  IconWrapper,
  TextDetail,
} from "./styles"

import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { PhoneIcon, EmailIcon, PinIcon } from "components/Icons/"

const data_contact_header = {
  page_title: "Kontakt:",
  title_1: "Masz pytania?",
  title_2: "Napisz lub zadzwoń.",
  title_3: "Chętnie pomożemy i doradzimy",
  contact_details: [
    {
      icon: <PhoneIcon color={"#ffba33"} />,
      detail: (
        <ContactLink href="tel:+48 572 185 689">+48 572 185 689</ContactLink>
      ),
    },
    {
      icon: <EmailIcon color={"#ffba33"} />,
      detail: (
        <ContactLink href="mailto:kontakt@klimalab.pl">
          kontakt@klimalab.pl
        </ContactLink>
      ),
    },
    {
      icon: <PinIcon color={"#ffba33"} />,
      detail: (
        <ContactSpan>
          KLIMALAB Krzysztof Flaga
          <br />
          Czarnochowice 538 <br />
          32-020 Wieliczka <br />
          NIP: 5170176249
        </ContactSpan>
      ),
    },
  ],
}
export const ContactHeader = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Contact/contact_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <ImageBox>
        <Image fluid={image.file.childImageSharp.fluid} />
      </ImageBox>
      <Wrapper>
        <TextBox>
          <TitlesWrap>
            <PageTitle>{data_contact_header.page_title}</PageTitle>
            <Title orange={true}>{data_contact_header.title_1}</Title>
            <Title>{data_contact_header.title_2}</Title>
            <Title>{data_contact_header.title_3}</Title>
          </TitlesWrap>
          <ContactDetails>
            {data_contact_header.contact_details.map((detail, i) => (
              <ContactDetail key={i}>
                <IconWrapper>{detail.icon}</IconWrapper>
                <TextDetail>{detail.detail}</TextDetail>
              </ContactDetail>
            ))}
          </ContactDetails>
        </TextBox>
      </Wrapper>
    </Container>
  )
}
