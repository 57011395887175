import React, {useEffect, useRef} from "react"
import {Iframe, IframeWrapper, TitleContainer,} from "./styles"
import {MediumHeader} from "../../../components/Typography";

export const CONTACT_IFRAME_NAME = "KLIMALAB_CONTACT_FORM";

const Form = ({src, name, handleScroll}) => {
  const iframe = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (!iframe.current || !event.origin.startsWith(process.env.GATSBY_EXTERNAL_FORMS_ORIGIN) || event.data.name !== name) {
        return;
      }

      if (event.data.height) {
        iframe.current.style.height = event.data.height + "px";
      }

      if (event.data.offset) {
        if (handleScroll) {
          handleScroll(event);
        }
      }

      if (event.data.type === "form-success") {
        if (window.dataLayer) {
          window.dataLayer.push({'event': event.data.name})
        }
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [name, src]);
  return (
    <>
      <TitleContainer>
        <MediumHeader>Napisz do nas</MediumHeader>
      </TitleContainer>
      <IframeWrapper>
        <Iframe ref={iframe} src={src} name={name} title="Formularz kontaktowy"/>
      </IframeWrapper>

    </>
  )
}

export {Form}
